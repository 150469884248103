import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import { API } from '../../config/config';
import { SERVER_IP, SERVER_CNAME } from '../../config/config';
import * as routes from '../../config/routes';
import { auth } from '../../firebase/firebase';
import moment from 'moment';
import Moment from 'react-moment';

import EventBus from 'eventbusjs';
import Loader from '../../includes/Loader';
import withAuthorization from '../withAuthorization';
import AuthService from '../Authentication/Auth';

class Billing extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            loading: false,
            month: "1",
			year: "1999",
			billings: []
		}
    }

	handleMonthChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
			month: value,
			loading: true
		}, () => {
            this.fetchBillings();
		});
    }

	handleYearChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
			year: value,
			loading: true
		}, () => {
            this.fetchBillings();
		});
    }

	fetchBillings = () => {
        let { month } = this.state;

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { idtoken: idtoken }
            fetch(API + "/billings?month=" + month, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {
				this.setState({ 
					billings: result.billings,
					loading: false 
				});
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	send = (contractid, contracttype) => {
		let { month, year } = this.state;
		let copy = this.state.billings;
		copy.forEach(billing => {
			if (billing.id==contractid) {
				billing.loading = true;
			}
		});
		this.setState({ 
			billings: copy
		});

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { 
				idtoken: idtoken,
				billingmonth: month,
				billingyear: year
			}
            fetch(API + "/billings/send?type=" + contracttype + "&id=" + contractid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {

				if (result.success) {
					let lastbilled = result.contract.lastbilled;
					let invoices = result.contract.invoices;
					let copy = this.state.billings;
					copy.forEach(billing => {
						if (billing.id==contractid) {
							billing.lastbilled = lastbilled;
							billing.invoices = invoices
							billing.loading = false;
							billing.error = false;
							billing.status = null;
						}
					});
					this.setState({ 
						billings: copy
					});
				} else {
					let copy = this.state.billings;
					copy.forEach(billing => {
						if (billing.id==contractid) {
							billing.loading = false;
							billing.error = true;
							billing.status = result.status;
						}
					});
					this.setState({ 
						billings: copy
					});
				}

				
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	updatecustomer = (contractid, contracttype) => {
		let { month, year } = this.state;
		let copy = this.state.billings;
		copy.forEach(billing => {
			if (billing.id==contractid) {
				billing.loading = true;
			}
		});
		this.setState({ 
			billings: copy
		});

        auth.currentUser.getIdToken().then(idtoken => {
            const json = { 
				idtoken: idtoken,
				billingmonth: month,
				billingyear: year
			}
            fetch(API + "/billings/updatecustomer?type=" + contracttype + "&id=" + contractid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            })
            .then(results => results.json())
            .then(result => {

				if (result.success) {
					let lastbilled = result.contract.lastbilled;
					let invoices = result.contract.invoices;
					let copy = this.state.billings;
					copy.forEach(billing => {
						if (billing.id==contractid) {
							billing.lastbilled = lastbilled;
							billing.invoices = invoices
							billing.loading = false;
							billing.error = false;
							billing.status = null;
						}
					});
					this.setState({ 
						billings: copy
					});
					// send actual bill
					this.send(contractid, contracttype);

				} else {
					let copy = this.state.billings;
					copy.forEach(billing => {
						if (billing.id==contractid) {
							billing.loading = false;
							billing.error = true;
							billing.status = result.status;
						}
					});
					this.setState({ 
						billings: copy
					});
				}

				
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
        });
    }

	


	sendAll = () => {
		let { month, year } = this.state;
		this.state.billings.forEach(billing => {
			setTimeout(function () {
				let isfound = false;
				if (billing.invoices) {
					billing.invoices.forEach(invoice => {
						if (invoice.month==month && invoice.year==year) {
							isfound = true;
						}
					});
					if (!isfound) {
						this.send(billing.id, billing.contracttype)
						//console.log(billing.id + " / " + billing.contracttype)
					}
				}
			}, 1000);
		});
    }

	checkLastbilled = (billing) => {
		let { month, year } = this.state;
		let isfound = false;
		if (billing.invoices) {

			billing.invoices.forEach(invoice => {
				if (invoice.month==month && invoice.year==year) {
					isfound = true;
				}
			});

			/*
			let lastmonth = (moment(billing.lastbilled.$date).month()+1)+""
			let lastyear = (moment(billing.lastbilled.$date).year())+""
			if (lastmonth==month && lastyear==year) {
				return true;
			} else {
				return false;
			}
			*/
		}
		return isfound;
    }

		// Create our number formatter.
	formatter = new Intl.NumberFormat('fi', {
		style: 'currency',
		currency: 'EUR',
		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	calcPrice = (billing) => {
		let price = billing.monthlyprice*billing.billingperiod
		return this.formatter.format(price)
    }

	calcTotalPrice = () => {
		let total = 0.00;
		this.state.billings.forEach(billing => {
			let price = billing.monthlyprice*billing.billingperiod
			total = total + price;
		});
		return total
    }

	componentDidMount() {
		let m = (moment().month()+1)+""
		let y = (moment().year())+""
		this.setState({
			month: m,
			year: y
		}, () => {
            this.fetchBillings();
		});
		// open side menu
		EventBus.dispatch("sidemenu-changed", true);
    }

	render() {
		const authUser = AuthService.getuser();
		const { loading, month, year, billings} = this.state
		const { t } = this.props;

		return (
			<>
				<div className="container">
					<div className="card mt-4">
						<div className="card-header bg-secondary-dark highlight text-white">{t("contractbilling.title")}</div>
						<div className="card-body">

							<div className="row">
								<div className="col-md-8">

									<div className="form-group mb-4">
										<label htmlFor="domain">{t("contractbilling.month")}</label>
										<select id="admin-form-month" className="custom-select" name="month" value={month} onChange={this.handleMonthChange}>
											<option value="" disabled hidden>{t("contractbilling.selectmonth")}</option>
											<option value="1">{t("contractbilling.january")}</option>
											<option value="2">{t("contractbilling.february")}</option>
											<option value="3">{t("contractbilling.march")}</option>
											<option value="4">{t("contractbilling.april")}</option>
											<option value="5">{t("contractbilling.may")}</option>
											<option value="6">{t("contractbilling.june")}</option>
											<option value="7">{t("contractbilling.july")}</option>
											<option value="8">{t("contractbilling.august")}</option>
											<option value="9">{t("contractbilling.september")}</option>
											<option value="10">{t("contractbilling.october")}</option>
											<option value="11">{t("contractbilling.november")}</option>
											<option value="12">{t("contractbilling.december")}</option>
										</select>
									</div>

								</div>
								<div className="col-md-4">
									
									<div className="form-group mb-4">
										<label htmlFor="domain">{t("contractbilling.year")}</label>
										<select id="admin-form-year" className="custom-select" name="year" value={year} onChange={this.handleYearChange}>
											<option value={(moment().year()-5)+""}>{moment().year()-5}</option>
											<option value={(moment().year()-4)+""}>{moment().year()-4}</option>
											<option value={(moment().year()-3)+""}>{moment().year()-3}</option>
											<option value={(moment().year()-2)+""}>{moment().year()-2}</option>
											<option value={(moment().year()-1)+""}>{moment().year()-1}</option>
											<option value={(moment().year())+""}>{moment().year()}</option>
											<option value={(moment().year()+1)+""}>{moment().year()+1}</option>
											<option value={(moment().year()+2)+""}>{moment().year()+2}</option>
										</select>
									</div>

								</div>
							</div>

							{loading 
							? 
								<div className="text-center mt-4">
									<Loader size="3x" classes="text-primary" />
								</div>
							: 
								<>
								{(billings && billings.length > 0) &&
									<div className="billinlist">

										<div className="row mb-1 ">
											<div className="col-md-3">{t("contractbilling.sitename")}</div>
											<div className="col-md-3">{t("contractbilling.customername")}</div>
											<div className="col-md-2 text-right">{t("contractbilling.price")}</div>
											<div className="col-md-2 text-right">{t("contractbilling.lastbilled")}</div>
											<div className="col-md-2"></div>
										</div>
										<hr />
										{billings.map((billing, i) => {
											return (
												<div key={billing.id}>
													<div className="row mb-2">
														<div className="col-md-3">
															{billing.contracttype=='site' 
															? <><Link className="title-link" to={routes.WEBSITE_HOME + `/${billing.id}`}>{billing.title} {billing.account && <>({billing.account})</>}</Link><br/></>
															: <><Link className="title-link" to={routes.CONTRACTS_EDIT + `/${billing.id}`}>{billing.title} {billing.account && <>({billing.account})</>}</Link><br/></>
															}
															
															<small>{t("contractbilling.billingmonth")} {billing.billingmonth}</small>
														</div>
														<div className="col-md-3">
															{billing.billingname}<br/>
															<small>{billing.billingcompany}</small>
														</div>
														<div className="col-md-2 text-right">
															{this.calcPrice(billing)}<br/>
															<small>{this.formatter.format(billing.monthlyprice)} * {billing.billingperiod} kk</small>
														</div>
														<div className="col-md-2 text-right">
															{(billing.lastbilled && billing.lastbilled.$date) 
															? <Moment format="D.M.YYYY">{billing.lastbilled.$date}</Moment>
															: <>-</>
															}
														</div>
														<div className="col-md-2 text-center">
															{billing.loading
															? <i className={'fa-solid fa-spinner fa-spin fa-2'}></i>
															: 
																<>
																{(billing.error)
																? <>
																	<i className={'fa fa-triangle-exclamation text-danger'} title={billing.status}></i>
																	<a className="text-small-button" href="#" onClick={() => this.updatecustomer(billing.id, billing.contracttype)}>{t("contractbilling.sendagainbtn")}</a>
																 </>
																: (
																	<>
																	{(this.checkLastbilled(billing))
																	? <>
																		<i className={'fa fa-check-circle-o text-success'}></i><a className="text-small-button" href="#" onClick={() => this.updatecustomer(billing.id, billing.contracttype)}>{t("contractbilling.sendagainbtn")}</a>
																		</>
																	: <button type="button" className="btn btn-default btn-sm" onClick={() => this.updatecustomer(billing.id, billing.contracttype)}>{t("contractbilling.sendbtn")}</button>
																	}
																	</>
																)
																}
																	</>
															}
														</div>
													</div>
													<hr />
												</div>
											)
										})}
										<div className="row mb-1 ">
											<div className="col-md-3"></div>
											<div className="col-md-3 text-right">
												{t("contractbilling.total")}<br/>
												{t("contractbilling.vat")}<br/>
												<strong>{t("contractbilling.totalwithvat")}</strong>
											</div>
											<div className="col-md-2 text-right">
												{this.formatter.format(this.calcTotalPrice())}<br/>
												{this.formatter.format((this.calcTotalPrice()/100)*24)}<br/>
												{this.formatter.format(this.calcTotalPrice()*1.24)}
												</div>
											<div className="col-md-2"></div>
											<div className="col-md-2">
												<button type="button" className="btn btn-secondary" onClick={() => this.sendAll()}>{t("contractbilling.sendallbtn")}</button>
											</div>
										</div>

									</div>
								}
								</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withAuthorization(withRouter(withTranslation("Admin")(Billing)));